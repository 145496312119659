import React from 'react';

import ScheduleRow from './ScheduleRow';
import ScheduleHeaderRow from './ScheduleHeaderRow';

class Schedule extends React.Component {

    render() {

        var columns = this.props.padelCenters.slice();
        var timeColumn = { label: "Tid", value: "0", isLoading: false };
        columns.unshift(timeColumn);

        var uniqueTimes = [...new Set(this.props.padelTimes.map(padelTime => padelTime.time))];

        var rows = [];
        for (var i = 0; i < uniqueTimes.length; i++) {
            const uniqueTime = uniqueTimes[i];
            var row = {}
            const timeAndDate = this.props.padelTimes.filter(p => p.time === uniqueTime)[0].datetime;
            row.time = uniqueTime;

            for (var j = 1; j < columns.length; j++) {
                const col = columns[j].label;
                row[col] = this.props.padelTimes.filter((p) => p.padelCenterName === col && p.time === uniqueTime)
                    .map((p) => {
                        return {
                            padelCourtName: p.padelCourtName,
                            bookingURL: p.bookingURL,
                            scheduleTime: timeAndDate,
                            padelCenterId: p.padelCenterId
                        }
                    });

                if (row[col].length === 0) {
                    row[col].push("Inga tider");
                }
            }
            rows.push(row);
        }

        var sortedRows = [...rows].sort((a, b) => {
            var aSplit = a.time.split(":");
            var bSplit = b.time.split(":");
            var aHours = parseInt(aSplit[0], 10);
            var bHours = parseInt(bSplit[0], 10);
            var aMinutes = parseInt(aSplit[1], 10);
            var bMinutes = parseInt(bSplit[1], 10);

            if (aHours === bHours) {
                return aMinutes - bMinutes;
            }
            return aHours - bHours;
        });

        return (
            <div>
                <table>
                    <thead>
                        <ScheduleHeaderRow isHeader={true} headerColumns={columns} />
                    </thead>
                    <tbody>
                        {sortedRows.map((r) => {
                            return <ScheduleRow key={r.time} row={r} />;
                        })}
                    </tbody>
                    <tfoot className='tableFooter'>
                        {rows.length > 15 ? <ScheduleHeaderRow isHeader={false} headerColumns={columns} /> : null}
                    </tfoot>
                </table>
            </div>
        )
    }

}

export default Schedule;