import ScheduleCell from './ScheduleCell';

import React from 'react';

class ScheduleRow extends React.Component {

     render() {
        return(
        <tr>
        {Object.entries(this.props.row).map(([key, value]) => {
            return <ScheduleCell key={key} keyName={key} value={value} />
             })}
        </tr>
        )
    }

}

export default ScheduleRow;