import React from 'react';
import moment from 'moment';
import { logClickUrl } from '../env'

class ScheduleFreeTimeCell extends React.Component {


    handleClick = (event) => {

        var courts = Array.from(new Set(this.props.value));
        var firstPadelTime = courts[0];
        var courtCount = Array.from(new Set(this.props.value.map(p => { return p.padelCourtName }))).length;
        var bookingLinkUrl = courts[0].bookingURL;
        var scheduleTimeBooked = courts[0].scheduleTime;

        var padelClickData = {
            PadelCenterId: firstPadelTime.padelCenterId.toString(),
            ScheduleTime: scheduleTimeBooked.format("YYYY-MM-DD HH:mm:ss"),
            TimeClicked: moment().format("YYYY-MM-DD HH:mm:ss"),
            NoOfCourtsFreeAtTime: courtCount.toString()
        };

        var body = JSON.stringify(padelClickData);
        fetch(logClickUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "Access-Control-Origin": "*"
            },
            body: body
        });

        window.open(bookingLinkUrl, "_blank")
    }

    render() {

        var courts = Array.from(new Set(this.props.value));
        var courtCount = Array.from(new Set(this.props.value.map(p => { return p.padelCourtName }))).length;
        courts.sort();

        return (<td onClick={this.handleClick} className='tableFreeTimeCell' key={this.props.keyName}>
            <div className='tableFreeTimeCellInner'>
                {courtCount}
            </div>
        </td>)
    }
}


export default ScheduleFreeTimeCell;