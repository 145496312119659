import React from 'react';
import { Spin } from 'antd';
import { useWindowDimensions } from '../helpers'

function ScheduleHeaderRow(props) {

    const { width } = useWindowDimensions();
    var outerHeaderDivStyle = {};
    var innerHeaderStyle = {};
        
    if(props.headerColumns && props.headerColumns.length > 0) {
      const countPadelCenters = props.headerColumns.length;
      var condFontSize = '';
      var condFontWeight = 600;
      var breakAll = false;

           
      if(width >= 1200) { 
        condFontSize = '14px';
        if(countPadelCenters > 10) {
          condFontSize = '12px';
        }
      } 
      else if(width >= 992) { 
        condFontSize = '14px';
        if(countPadelCenters > 10) {
          condFontSize = '10px';
        }
      }    
      else if(width >= 768) { 
        condFontSize = '14px';
        if(countPadelCenters > 8) {
          condFontSize = '12px';
        }
        if(countPadelCenters > 10) {
          condFontSize = '10px'
        }
        // if(countPadelCenters > 12) {
        //   breakAll = true
        // }
      }
      else if(width > 600) { 
        condFontSize = '12px';
        if(countPadelCenters > 8) {
          condFontSize = '10px';
        }
        if(countPadelCenters > 10) {
          condFontSize = '8px'
        }
        if(countPadelCenters > 12) {
          breakAll = true
        }
      } else if(width <= 600) { 
        condFontSize = '9px';
        if(countPadelCenters > 8) {
          condFontSize = '8px';
        }
        if(countPadelCenters > 9) {
          condFontSize = '6px'
        }
        if(countPadelCenters > 11) {
          condFontSize = '5px'
        }

        if(countPadelCenters > 9) {
          breakAll = true
        } 
      }

      innerHeaderStyle = {
        fontSize: condFontSize,
        fontWeight: condFontWeight
      }

      if(breakAll === true) {
        outerHeaderDivStyle = {
          wordBreak: 'break-all',
          display: 'block'
        }
      } else {
        outerHeaderDivStyle = {
          display: 'block'
        }
      }
    }
  

return (      
      // eslint-disable-next-line 
      <tr style={!props.isHeader ? {position:"sticky", position:"-webkit-sticky", bottom:"0"} : {}} key='title'>
      {props.headerColumns.map((p) => {
          if(p.label === "Tid") {
              return props.isHeader ? <th className='tableTimeHeader' key={p.label}></th> : <td className='tableTimeHeader' key={p.label}></td>;
          }

          return props.isHeader ?
        (<th className='tableHeader' key={p.label}>
                <Spin spinning={p.isLoading === true}>
                  <div style={outerHeaderDivStyle}>
                  <h6 style={innerHeaderStyle}>{p.label}</h6>
                </div>
                </Spin>
              </th>
             ) :
          (<td className='tableFooter' key={p.label}>
                <Spin spinning={p.isLoading === true}>
                  <div style={outerHeaderDivStyle}>
                  <h6 style={innerHeaderStyle}>{p.label}</h6>
                </div>
                </Spin>
            </td>)   
      })}
    </tr>
    )
}


export default ScheduleHeaderRow;