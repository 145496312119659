import 'antd/dist/antd.css';
import './index.css';
import './App.css';
import { useMeasure } from './helpers'
import { useSpring, animated, config } from 'react-spring'
import Schedule from './components/Schedule';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/sv';
import { DatePicker, Checkbox, Button, Spin, Icon, message, Select, Slider, Tag, Modal, Carousel } from 'antd';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { getpadeltimesUrl, getpadelcentersUrl, logSearchUrl } from './env'

function App() {

  library.add(fab, faEnvelope)
  const [error, setError] = useState(null)
  const [date, setDate] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [regions, setRegions] = useState([])
  const [regionOptions, setRegionOptions] = useState([])
  const [selectedRegions, setSelectedRegions] = useState([])
  const [isPadelCentersLoading, setIsPadelCentersLoading] = useState(true)
  const [showPadelCenters, setShowPadelCenters] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [minDurationSelected, setMinDurationSelected] = useState(60);
  const [padelCenters, setPadelCenters] = useState([])
  const [filteredPadelCenters, setFilteredPadelCenters] = useState([])
  const [selectedPadelCenters, setSelectedPadelCenters] = useState([])
  const [padelCentersToBeSearched, setPadelCentersToBeSearched] = useState([])
  const [padelTimes, setPadelTimes] = useState([])
  const [filteredTimes, setFilteredTimes] = useState([])
  const [sortedTimes, setSortedTimes] = useState([])

  const siderAndHeaderColor = '#2E3925';
  const antIcon = <Icon type="loading" style={{ fontSize: 64 }} spin />;

  const durationMarks = {
    0: '0',
    60: '60',
    90: '90',
    120: '120',
    180: '180'
  }

  const [bind, { height }] = useMeasure()
  const props = useSpring({ config: config.stiff, height })
  const props2 = useSpring({ config: config.molasses, display: showPadelCenters ? 'inherit' : 'none', opacity: showPadelCenters ? 1 : 0 })

  useEffect(() => {
    // setSelectedTimeValues([1, 2, 3]);
    setDate(moment());
    getPadelCenters();
  }, []);

  useEffect(() => {
    if (regions && regions.length > 0) {
      var children = [];
      for (let i = 0; i < regions.length; i++) {
        var curRegion = regions[i];
        children.push(<Option key={curRegion.regionId}>{curRegion.regionName}</Option>);
      }
      setRegionOptions(children);

      var savedRegions = localStorage.getItem("savedSelectedRegions");
      if (savedRegions) {
        var savedRegionsParsed = JSON.parse(savedRegions.slice());
        var existingSavedRegions = savedRegionsParsed.filter(p => regions.map(q => q.regionId.toString()).includes(p));
        setSelectedRegions(existingSavedRegions); //check if saved regions exist in current regions
      }
    }
  }, [regions]);

  useEffect(() => {
    var padelCentersFilteredByRegion = padelCenters.filter((p) => {
      return selectedRegions.includes(p.regionId.toString());
    }
    );
    setFilteredPadelCenters(padelCentersFilteredByRegion);
  }, [selectedRegions, padelCenters]);

  useEffect(() => {
    var filteredByDuration = padelTimes.filter(p => p.maxDurationSameCourt >= minDurationSelected);
    setFilteredTimes(filteredByDuration);
  }, [padelTimes, minDurationSelected]);

  useEffect(() => {
    console.log(error);
  }, [error]);


  const getPadelCenters = () => {
    fetch(getpadelcentersUrl)
      .then(res => res.json())
      .then(
        (result) => {

          var padelCentersFormatted = result.map((p) => {

            var padelCenterFormatted = {
              "label": p.name,
              "value": p.padelCenterId,
              "lat": p.latitude,
              "long": p.longitude,
              "regionId": p.regionId,
              "regionName": p.regionName,
              "city": p.city
            };
            return padelCenterFormatted;
          });

          var nonUniqueRegions = padelCentersFormatted.map(p => {
            var regionsFormatted = {
              "regionId": p.regionId,
              "regionName": p.regionName
            }
            return regionsFormatted;
          });

          const uniqueRegions = [];
          const map = new Map();
          for (const item of nonUniqueRegions) {
            if (!map.has(item.regionId)) {
              map.set(item.regionId, true);    // set any value to Map
              uniqueRegions.push({
                regionId: item.regionId,
                regionName: item.regionName
              });
            }
          }

          var savedPadelCenters = localStorage.getItem("savedPadelCenters");
          if (savedPadelCenters) {
            var allSelectedPadelCenters = padelCentersFormatted.slice();
            var parsedSavedPadelCenters = JSON.parse(savedPadelCenters);
            allSelectedPadelCenters = padelCentersFormatted.filter(p => parsedSavedPadelCenters.map(q => q.value).includes(p.value));
            setSelectedPadelCenters(allSelectedPadelCenters);
          } else {
            setSelectedPadelCenters([]);
          }

          setRegions([...uniqueRegions]);
          setPadelCenters(padelCentersFormatted);
          setIsPadelCentersLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      )
  }
  const showInfoModal = () => {
    Modal.info({
      title: 'Minimum minuter',
      content: (
        <div>
          <p>Antal minuter i följd man minst kan boka på samma bana.</p>
          <p>Om en bana har bokningsbara 30-minuterstider kan det vara intressant att filtrera på minst 90 min exempelvis.
          Då slipper man se de tider som endast sträcker sig 30 eller 60 minuter totalt.
          </p>
          <p>Detta filter tar även med tider som kräver att man bokar mer än vad man kanske tänkt sig.
            Exempelvis om man filtrerar på 90 minuter så kan det innebära att sökningen hittar två 60-minuterstider efter varann på samma bana.</p>
        </div>
      ),
      maskClosable: true
    });
  }

  const logPadelTimeSearch = (padelCenterIds) => {
    try {
      var padelCenterIdsArray = Array.from(new Set(padelCenterIds.map(p => p.value)));
      var dateSearchedFor = date;
      var searchedPerformedDateTime = moment();
      var padelSearchData = {
        PadelCenterIds: padelCenterIdsArray,
        SearchPerformedDateTime: searchedPerformedDateTime.format("YYYY-MM-DD HH:mm:ss"),
        DateSearchedFor: dateSearchedFor.format("YYYY-MM-DD HH:mm:ss"),
      };

      var requestBody = JSON.stringify(padelSearchData);
      fetch(logSearchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "*/*",
          "Access-Control-Origin": "*"
        },
        body: requestBody
      }).then(res => res.json())
        .then(
          (result) => {

          }
          ,
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setError(error);
          });
    }
    catch {

    }

  }

  const fetchData = () => {

    var padelCentersToBeSearched = filteredPadelCenters.filter(p => {
      return selectedPadelCenters.map(q => q.value).includes(p.value);
    });

    if (!date) {
      message.info('Var god välj ett datum.');
      return;
    }

    if (padelCentersToBeSearched.length === 0) {
      message.info('Var god välj minst ett padelcenter.');
      return;
    }

    if (padelCentersToBeSearched.length > 15) {
      message.info('Välj max 15 padelcenter.');
      return;
    }

    logPadelTimeSearch(padelCentersToBeSearched);

    padelCentersToBeSearched.forEach((p) => p.isLoading = true);

    setShowPadelCenters(false);
    setPadelTimes([]);
    setSortedTimes([]);
    setFilteredTimes([]);
    setIsLoaded(false);
    setIsLoading(true);
    setPadelCentersToBeSearched(padelCentersToBeSearched);

    localStorage.setItem("savedSelectedRegions", JSON.stringify(selectedRegions));

    var pickedDate = date ? date.format('YYYY-MM-DD') : null;

    padelCentersToBeSearched.forEach(padelCenterId => {
      var url = `${getpadeltimesUrl}?padelCourtId=${padelCenterId.value}&date=${pickedDate}`; // DEV
      fetch(url)
        .then(response => response.json())
        .then(
          (result) => {
            var filteredResultNotAfterNow = result.filter((p) => {
              var currentMoment = moment();
              var dateTime = moment(p.time);
              var earliestTime = date.set({ hour: 4, minute: 59 }); //todo: add support for nightly hours

              if (dateTime < currentMoment) {
                return false;
              }
              if (dateTime < earliestTime) {
                return false;
              }
              return true;
            });

            var padelTimesFormatted = filteredResultNotAfterNow.map((p) => {

              var dateTime = moment(p.time);
              var dateString = dateTime.format("HH:mm");
              var endDateTime = moment(p.endTime);
              var durationMinutes = p.durationMinutes;

              var maximumDurationSameCourt = getMaximumDuration(0, p, filteredResultNotAfterNow, true);
              //  var maximumDurationOtherCourt = getMaximumDuration(0, p, filteredResultNotAfterNow, false);

              var padelTimeFormatted = {
                "padelCourtName": p.padelCourtName,
                "padelCenterName": p.padelCenterName,
                "time": dateString,
                "datetime": dateTime,
                "endTime": endDateTime,
                "durationMinutes": durationMinutes,
                "maxDurationSameCourt": maximumDurationSameCourt,
                // "maxDurationOtherCourt": maximumDurationOtherCourt,
                "padelCenterId": p.padelCenterId,
                "bookingURL": p.bookingURL
              };
              return padelTimeFormatted;
            });

            setIsLoading(false);
            setIsLoaded(true);

            var allSelectedPadelCenters = [...padelCentersToBeSearched];
            allSelectedPadelCenters.forEach(p => {
              if (p.value === padelCenterId.value)
                p.isLoading = false;
            });
            setPadelCentersToBeSearched(allSelectedPadelCenters.slice());
            setPadelTimes(padelTimes => [...padelTimes, ...padelTimesFormatted]);
            setFilteredTimes(padelTimes => [...padelTimes, ...padelTimesFormatted]);

            var allSortedTimes = [...sortedTimes, ...padelTimesFormatted.map(padelTime => padelTime.time)].filter((x, i, a) => a.indexOf(x) === i).sort((a, b) => {
              var aSplit = a.split(":");
              var bSplit = b.split(":");
              var aHours = parseInt(aSplit[0], 10);
              var bHours = parseInt(bSplit[0], 10);
              var aMinutes = parseInt(aSplit[1], 10);
              var bMinutes = parseInt(bSplit[1], 10);

              if (aHours === bHours) {
                return aMinutes - bMinutes;
              }
              return aHours - bHours;
            });

            setSortedTimes(sortedTimes => [...sortedTimes, ...allSortedTimes]);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setError(error);
          }
        )
    });
  }

  const getMaximumDuration = (duration, padelTime, padelTimes, onlySameCourt) => {
    var currentDuration = duration + padelTime.durationMinutes;
    var padelTimesStartingOnEndTime = padelTimes.filter(p => p.time === padelTime.endTime);

    if (onlySameCourt === true) {
      padelTimesStartingOnEndTime = padelTimesStartingOnEndTime.filter(p => p.padelCourtName === padelTime.padelCourtName);
    }

    if (padelTimesStartingOnEndTime.length === 0) {
      return currentDuration;
    }

    var maxDuration = 0;

    for (var i = 0; i < padelTimesStartingOnEndTime.length; i++) {
      var currentPadelTime = padelTimesStartingOnEndTime[i];
      var currentMaxDuration = getMaximumDuration(currentDuration, currentPadelTime, padelTimes, onlySameCourt);
      if (currentMaxDuration > maxDuration) {
        maxDuration = currentMaxDuration;
      }
    }

    return maxDuration;
  }

  const checkBoxOnChange = (parameter) => (value) => {
    var selectedPadelCentersToBeSaved = selectedPadelCenters.filter(p => p.regionId.toString() !== parameter);
    var allSelectedPadelCenters = [...selectedPadelCentersToBeSaved.map(p => p.value), ...value];
    var uniqueAllSelectedPadelCenters = Array.from(new Set(allSelectedPadelCenters));
    var newSelectedPadelCenters = padelCenters.filter(p => uniqueAllSelectedPadelCenters.includes(p.value));

    newSelectedPadelCenters.forEach(p => {
      p.isLoading = true;
    });

    localStorage.setItem("savedPadelCenters", JSON.stringify(newSelectedPadelCenters));

    setSelectedPadelCenters(newSelectedPadelCenters);
    setIsLoaded(false);
  };

  const filterByMinDuration = (duration) => {
    setMinDurationSelected(duration);
  };

  const resetMinDuration = () => {
    setMinDurationSelected(0);
  }

  const handleChange = d => {
    setDate(d);
    setIsLoaded(false);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && (current < moment().subtract(1, 'd') || current > moment().add(14, 'd'));
  };

  const toggleShowPadelCenters = () => {
    var toggled = !showPadelCenters;
    setShowPadelCenters(toggled);
  }


  const toggleShowFilter = () => {
    var toggled = !showFilter;
    setShowFilter(toggled);
  }

  const openEmail = () => {
    window.location.href = 'mailto:sandskaer@gmail.com';
  }

  const openFbLink = () => {
    window.open('https://www.facebook.com/padeltider', "_blank")
  }

  const { Option } = Select;

  const onRegionSelect = (reg) => {
    setSelectedRegions(reg);
  }

  const select = (<Select
    mode="multiple"
    className='regionSelector'
    placeholder="Välj region"
    defaultValue={selectedRegions}
    value={selectedRegions}
    onChange={onRegionSelect}
  >
    {regionOptions}
  </Select>);

  return (
    <div className='Site'>
      <div style={{ textAlign: "center", background: siderAndHeaderColor, flex: 'none', width: "100%" }} >
        <div style={{ marginTop: "3vh" }}>
          <h1 className='mainHeader'>
            padeltider
        </h1>

          <div style={{ display: "inline-block", textAlign: "center" }}>

            <div className='datePickerOuterDiv'>
              <DatePicker disabledDate={disabledDate} onChange={handleChange} placeholder="Välj datum" defaultPickerValue={moment()} defaultValue={moment()} />
              {!showPadelCenters ? <div className='belowDatePickerSeparator'></div> : null}
            </div>
            <div style={{}}>

              {/* <div style={{marginTop:"10px"}}>
        <Checkbox.Group options={timeOptions} defaultValue={[1,2,3]} onChange={this.timeCheckboxOnChange}/>
        </div> */}
              <animated.div style={{ overflow: 'hidden', ...props }}>
                <div {...bind} className='padelCentersOuterDiv'>
                  {true ?
                    <animated.div style={props2}>

                      {!isPadelCentersLoading ?

                        <div style={{ overflow: 'visible', color: "#4C4F4B", maxWidth: "100vw" }}>
                          {select}
                          <div className='regionsOuterDiv'>
                            {selectedRegions.map(p => {
                              return (<div key={p}>
                                <h2 className='regionTitle'>{regions.filter(q => q.regionId.toString() === p)[0].regionName}</h2>
                                <Checkbox.Group defaultValue={selectedPadelCenters.map((q) => q.value)} options={filteredPadelCenters.filter(r => r.regionId.toString() === p)} onChange={checkBoxOnChange(p)} />
                              </div>)
                            })}
                          </div>
                          {selectedRegions.length > 0 ? <br /> : <br />}
                        </div>
                        :
                        null}

                      {isPadelCentersLoading ? <div style={{ margin: '2vh 2vw' }}><Spin tip="Laddar padelcenter..." indicator={antIcon} /></div> : null}
                    </animated.div> :
                    null
                  }
                </div>
              </animated.div>
            </div>
          </div>
        </div>

        <div className='searchButtonsDiv'>
          <Button ghost block size='large' loading={isLoading} type="normal" icon="search" onClick={fetchData}>Sök lediga tider</Button>
          {!showPadelCenters ?
            <Button ghost block size='large' type="normal" icon="arrows-alt" onClick={toggleShowPadelCenters}>
              Välj padelcenter
          </Button> :
            <Button ghost block size='large' type="normal" icon="shrink" onClick={toggleShowPadelCenters}>
              Dölj padelcenter
          </Button>
          }
        </div>
        {showFilter ?
          <div className='filterOuterDiv'>
            <h2 className='filterLabel'>Minimum minuter <Icon type="info-circle" className="infoIcon" onClick={showInfoModal} /></h2>
            <Slider marks={durationMarks} step={null} defaultValue={minDurationSelected} value={minDurationSelected} min={0} max={180} included={false} onChange={filterByMinDuration} />
          </div>
          : null}
        <div className='searchButtonsDiv'>
          {!showFilter ?
            <Button ghost block size='large' type="normal" icon="filter" onClick={toggleShowFilter}>
              Visa filter
          </Button> :
            <Button ghost block size='large' type="normal" icon="filter" onClick={toggleShowFilter}>
              Dölj filter
          </Button>
          }
        </div>
      </div>
      <div className='Site-content'>
        <div className='activeFilters'>
          {minDurationSelected ?
            <Tag color="#f50" closable onClose={resetMinDuration} className="filterTag">
              Minuter &gt;= {minDurationSelected}
            </Tag> : null
          }
        </div>
        <div>
          {!isLoaded ?
            <div className="carousel-wrapper margin-small">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "left", textAlign: "left", marginBottom: "10vh" }}>
                <div className='no-schedule-area'>

                  <p>
                    1. Välj datum (dagens datum är förvalt)
              <br />
              2. Välj region(er)
              <br />
              3. Välj hallar att söka för
              <br />
              4. Sök!
              <br />
                    <br />
              Observera att det går att klicka på en tid för att komma till bokningssidan.
              <br />
                    <br />
              Om du har valt regioner och hallar i tidigare sökningar på denna enhet så har dessa sparats och då går det bra att söka direkt.
            </p>
                </div>
              </div>
            </div>
            : null}
          {isLoaded ?
            <div>
              <Schedule padelCenters={padelCentersToBeSearched} padelTimes={filteredTimes} />
            </div>
            : null}
        </div>
      </div>
      <div style={{ textAlign: "center", height: "50px", flex: "none", background: siderAndHeaderColor, width: "100%" }}>
        <FontAwesomeIcon icon={faEnvelope} pull="right" style={{ color: "#f9f8f4", fontSize: "2.1em", margin: "10px", cursor: "pointer" }} onClick={openEmail} />
        <FontAwesomeIcon icon={['fab', 'facebook']} pull="right" style={{ color: "#f9f8f4", fontSize: "2.1em", margin: "10px", cursor: "pointer" }} onClick={openFbLink} />
      </div>
    </div>
  );
}

export default App;