

// module.exports = {
//     getpadelcentersUrl: 'https://dev-getpadelcenters.azurewebsites.net/api/getpadelcenters',
//     getpadeltimesUrl: 'https://f7uytsh4yj.execute-api.eu-north-1.amazonaws.com/default/dev-getpadeltimes',
//     logClickUrl : 'https://dev-loganalytics.azurewebsites.net/api/logpadeltimeclick',
//     logSearchUrl: 'https://dev-loganalytics.azurewebsites.net/api/logpadeltimesearch',
//   };
  

module.exports = {
    getpadelcentersUrl: 'https://prod-getpadelcenters.azurewebsites.net/api/getpadelcenters',
    getpadeltimesUrl: '	https://gllssoat8g.execute-api.eu-north-1.amazonaws.com/default/prod-getpadeltimes',
    logClickUrl : 'https://prod-loganalytics.azurewebsites.net/api/logpadeltimeclick',
    logSearchUrl: 'https://prod-loganalytics.azurewebsites.net/api/logpadeltimesearch',
  };
  