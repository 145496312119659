import React from 'react';
import ScheduleFreeTimeCell from './ScheduleFreeTimeCell';

class ScheduleCell extends React.Component {

     render() {
        if(this.props.keyName === "time") {
            return <td className='timeCellOuter' key={this.props.keyName}><h6 className='timeCell'><b>{this.props.value}</b></h6></td>;
        }
        if(this.props.value.includes("Inga tider")) {
           
            return <td className='noTimeCellOuter' key={this.props.keyName}>
                <div className='noTimeCellInnerDiv'>
                </div></td>;
        }
        if(!this.props.value) {
            return <td className='noValueCellOuter' key={this.props.keyName}>
            <div className='noValueCellInnerDiv'>
            </div></td>;
        }     
        return <ScheduleFreeTimeCell key={this.props.keyName} keyName={this.props.keyName} value={this.props.value}/>
    }
}

export default ScheduleCell;